<template>
  <v-navigation-drawer v-model="navigationDrawer" app flat>
    <!-- Logo -->
    <app-logo class="ma-3" max-height="55px" />

    <!-- Profile -->
    <app-navigation-profile />

    <!-- Partner -->
    <template v-if="hasPermission('carsharing.show')">
      <v-divider />
      <app-navigation-partner />
    </template>

    <!-- Status -->
    <template v-if="hasPermission('update_absence_status')">
      <v-divider />
      <app-navigation-status />
    </template>

    <!-- Globalsearch -->
    <template v-if="hasPermission('globalsearch')">
      <v-divider />
      <app-navigation-globalsearch />
    </template>

    <!-- Notifications -->
    <template v-if="hasPermission('notifications')">
      <v-divider />
      <app-navigation-notifications />
    </template>

    <!-- Todos -->
    <template v-if="hasPermission('todos')">
      <v-divider />
      <app-navigation-todos />
    </template>

    <!-- personalmarkt.online -->
    <template v-if="hasPermission('personalmarkt.online')">
      <v-divider />
      <app-navigation-pmo />
    </template>

    <!-- Navigation -->
    <v-divider />
    <app-navigation-list-main />

    <!-- Quickactions -->
    <v-divider />
    <app-navigation-list-quickactions />

    <!-- Theme -->
    <v-divider />
    <v-container>
      <v-row align="center">
        <v-col cols="12" class="text-center">
          <theme-switcher small />
        </v-col>
      </v-row>
    </v-container>

    <template #append>
      <!-- Theme -->
      <v-divider />

      <v-toolbar flat dense>
        <version class="text-caption" />

        <v-spacer />

        <debug-dialog>
          <template #activator="{ on }">
            <v-icon x-small v-on="on">$debug</v-icon>
          </template>
        </debug-dialog>
      </v-toolbar>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

import ThemeSwitcher from '@/components/ThemeSwitcher'
import DebugDialog from '@/components/DebugDialog'
import Version from '@/components/Version'
import AppLogo from './AppLogo'
import AppNavigationProfile from './AppNavigationProfile'
import AppNavigationPartner from './AppNavigationPartner'
import AppNavigationStatus from './AppNavigationStatus'
import AppNavigationGlobalsearch from './AppNavigationGlobalsearch'
import AppNavigationNotifications from './AppNavigationNotifications'
import AppNavigationTodos from './AppNavigationTodos'
import AppNavigationPmo from './AppNavigationPmo'
import AppNavigationListMain from './AppNavigationListMain'
import AppNavigationListQuickactions from './AppNavigationListQuickactions'

export default {
  name: 'AppNavigationDrawer',

  components: {
    ThemeSwitcher,
    DebugDialog,
    Version,
    AppLogo,
    AppNavigationProfile,
    AppNavigationPartner,
    AppNavigationStatus,
    AppNavigationGlobalsearch,
    AppNavigationNotifications,
    AppNavigationTodos,
    AppNavigationPmo,
    AppNavigationListMain,
    AppNavigationListQuickactions
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    // vuex two-way binding
    navigationDrawer: {
      get() {
        return this.$store.state.layout.navigationDrawer
      },
      set(val) {
        this.$store.commit('layout/setNavigationDrawer', val)
      }
    }
  }
}
</script>
