import Vue from 'vue'

Vue.filter('importType', function (value) {
  if (!value) return ''

  switch (value) {
    case 'BESTAND_BGV':
      return 'Bestand (BGV)'

    case 'BESTAND_BGV_CARSHARING':
      return 'Bestand-Carsharing (BGV)'

    case 'BESTAND_BGV_CARSHARING_CARS':
      return 'Bestand-Carsharing Fahrzeuge (BGV)'

    case 'FAHRZEUGMARKT_AUTOSYS_CUSTOMERS':
      return 'fahrzeugmarkt.online | Import der AUTOSYS Kundendaten'

    case 'FAHRZEUGMARKT_AUTOSYS_VEHICLES':
      return 'fahrzeugmarkt.online | Import der AUTOSYS Fahrzeugdaten'

    case 'FAHRZEUGMARKT_CSV_MOBILE':
      return 'fahrzeugmarkt.online | Import der Mobile-CSV'

    case 'FAHRZEUGMARKT_CDAT_VEHICLES':
      return 'fahrzeugmarkt.online | Import der CDAT Fahrzeugdaten'

    default:
      return value
  }
})
