import UserApi from '@/api/user.js'

export default {
  namespaced: true,

  state: {
    header_title_text: null,
    images: {
      light: {
        logo: null,
        favicon: null
      },
      dark: {
        logo: null,
        favicon: null
      }
    },
    themes: {
      // themes will be loaded in bootstrap.js and "load" action below
      light: {},
      dark: {}
    },
    dark: false
  },

  mutations: {
    setHeaderTitle(state, text) {
      state.header_title_text = text
    },

    setImages(state, images) {
      state.images.light.logo = images.logo
      state.images.light.favicon = images.favicon || state.images.light.favicon // only if set, otherwise we use the default one
      state.images.dark.logo = images.logo
      state.images.dark.favicon = images.favicon || state.images.dark.favicon // only if set, otherwise we use the default one
    },

    setImagesLight(state, images) {
      state.images.light.logo = images.logo
      state.images.light.favicon = images.favicon || state.images.light.favicon // only if set, otherwise we use the default one
    },

    setImagesDark(state, images) {
      state.images.dark.logo = images.logo
      state.images.dark.favicon = images.favicon || state.images.dark.favicon // only if set, otherwise we use the default one
    },

    setThemes(state, theme) {
      state.themes = {
        light: { ...theme },
        dark: { ...theme }
      }
    },

    setThemeLight(state, theme) {
      state.themes = {
        ...state.themes,
        light: { ...theme }
      }
    },

    setThemeDark(state, theme) {
      state.themes = {
        ...state.themes,
        dark: { ...theme }
      }
    },

    setDark(state, dark) {
      state.dark = dark
    }
  },

  actions: {
    loadColorScheme({ commit }) {
      if (window.matchMedia) {
        const dark = !!window.matchMedia('(prefers-color-scheme: dark)').matches
        commit('setDark', dark)
      }
    },

    load({ commit, dispatch, rootState, rootGetters }) {
      return new Promise((resolve) => {
        const assetURL = process.env.VUE_APP_ASSET_URL

        dispatch('loadColorScheme')

        switch (rootState.client) {
          case 'fahrzeugmarkt.online':
            commit('setImages', {
              logo: `${assetURL}/fahrzeugmarkt.online/static/logo.svg`,
              favicon: `${assetURL}/fahrzeugmarkt.online/static/favicon.png`
            })
            commit('setHeaderTitle', 'fahrzeugmarkt.online')
            commit('setThemes', {
              primary: '#1976D2',
              secondary: '#83BC2A',
              accent: '#82B1FF',
              error: '#FF5252',
              info: '#2196F3',
              success: '#4CAF50',
              warning: '#FFC107'
            })

            break

          case 'freshforfinance':
          default:
            commit('setImages', {
              logo: `${assetURL}/freshforfinance/static/logo.svg`,
              favicon: `${assetURL}/freshforfinance/static/favicon.png`
            })
            commit('setHeaderTitle', 'fresh for finance')
            commit('setThemes', {
              primary: '#F79600',
              secondary: '#A1C51B',
              accent: '#A0C41A',
              error: '#FF5252',
              info: '#2196F3',
              success: '#4CAF50',
              warning: '#FFC107'
            })
            break
        }

        // get theme from user if the user is logged in
        if (rootState.auth.token && rootState.client) {
          const partner = rootGetters['auth/activePartner'] ?? null

          UserApi.loadTheme(rootState.client, partner)
            .then((data) => {
              commit('setImagesLight', data.light.images)
              commit('setImagesDark', data.dark.images)
              commit('setThemeLight', data.light.colors)
              commit('setThemeDark', data.dark.colors)
            })
            .catch(() => {
              // we will ignore the error and just use the default theme (from above)
              resolve()
            })
        }

        resolve()
      })
    }
  },

  getters: {
    full: (getters) => ({
      ...getters.images,
      ...getters.colors
    }),
    images: (state) => ({
      logo: state.dark ? state.images.dark.logo : state.images.light.logo,
      favicon: state.dark
        ? state.images.dark.favicon
        : state.images.light.favicon
    }),
    header: (state) => ({
      header_title_text: state.header_title_text
    }),
    colors: (state, getters) => (state.dark ? getters.dark : getters.light),
    light: (state) => state.themes.light,
    dark: (state) => state.themes.dark
  }
}
